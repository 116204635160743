import { React, useState, useEffect } from 'react';
import style from './JobBoxComponent.module.css';

const JobBoxComponent = (props) => {
  const { title, subtitle, img, content, hover } = props;
  const [isHovered, setIsHovered] = useState(false);
  const width = window.innerWidth;
  useEffect(() => {
    // 组件挂载完成时的逻辑
    console.log(`Component mounted with props:`);
    const index = img.split('banner')[1].substr(0, 1)
    if (index == 1) {
      hover(index);
      setIsHovered(true);
    }

    // 清理函数
    return () => {
      console.log(`Component will unmount`);
    };
  }, []);

  const handleMouseEnter = (e) => {

    const index = e.split('banner')[1].substr(0, 1)
    hover(index);
    setIsHovered(true);
  };

  const handleMouseLeave = (e) => {
    setIsHovered(false);
  };

  return (
    <div
      onMouseEnter={() => { handleMouseEnter(img) }}
      onMouseLeave={handleMouseLeave}
      className={style.box}
      style={{
        background: isHovered ? '#E45423' : '#2F334C',
        color: isHovered ? '#000' : '#fff'
      }
      }>
      <div className={style.single_box}>
        <div className={style.title_box}>
          <div className={style.title}>
            {title}
          </div>
          <div className={style.subtitle}>
            {subtitle}
          </div>
        </div>
        <div className={style.content_box}>
          {content}
        </div>
      </div>
    </div>
  );
};

export default JobBoxComponent;