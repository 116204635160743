import logo from './assets/header/logo.png'
import banner1 from './assets/banner/banner1.png'
import banner2 from './assets/banner/banner2.png'
import banner3 from './assets/banner/banner3.jpg'


import suitableTitle from './assets/suitable/title.png'
import suitable1 from './assets/suitable/suitable1.png'
import suitable2 from './assets/suitable/suitable2.png'
import suitable3 from './assets/suitable/suitable3.png'
import suitable4 from './assets/suitable/suitable4.png'

import hotClassTitle from './assets/hotclass/title.png'
import hotClassBtn from './assets/hotclass/btn.png'
import hotClassBg from './assets/hotclass/background.png'
import hotClassContent1 from './assets/hotclass/class_content1.png'
import hotClassContent2 from './assets/hotclass/class_content2.png'
import hotClassContent3 from './assets/hotclass/class_content3.png'

import jobTitle from './assets/jobchoose/title.png'
import jobs1 from './assets/jobchoose/banner1.png'
import jobs2 from './assets/jobchoose/banner2.png'
import jobs3 from './assets/jobchoose/banner3.png'
import jobs4 from './assets/jobchoose/banner4.png'
import jobs5 from './assets/jobchoose/banner5.png'
import jobBtn from './assets/jobchoose/btn.png'

import workshowTitle from './assets/worksshow/title.png'
import workShow from './assets/worksshow/show.png'

import studyTitle from './assets/study/title.png'
import study1 from './assets/study/service1.png'
import study2 from './assets/study/service2.png'
import study3 from './assets/study/service3.png'

import teacherTitle from './assets/teacher/title.png'
import teacher1 from './assets/teacher/teacher1.png'
import teacher2 from './assets/teacher/teacher2.png'
import teacher3 from './assets/teacher/teacher3.png'


import aboutusTitle from './assets/aboutus/title.png'
import aboutusPerson from './assets/aboutus/person.png'
import aboutusSubtitle from './assets/aboutus/subtitle.png'

import companyTitle from './assets/company/title.png'
import companys from './assets/company/company.png'

import close from './assets/modal/close.png'

import foot from './assets/footer/foot.png'
import footMobile from './assets/footer/foot_mobile.png';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import JobBoxComponent from './component/JobBoxComponent/JobBoxComponent';
import { useRef, useState, useEffect } from 'react';

function App() {
    const titleBox = useRef(null);

    //const [foot, setFoot] = useState(footPc);
    const [show, setShow] = useState(false);

    // const setFootPc = () => {
    //   setFoot(footPc)
    // }

    // const setFootMobile = () => {
    //   setFoot(footMobile)
    // }
    const [jobImg, setJobsImg] = useState(jobs1);

    const hoverJobs = (index) => {
        const jobsArr = [jobs1, jobs2, jobs3, jobs4, jobs5]
        setJobsImg(jobsArr[index - 1]);
    };

    const width = window.innerWidth;
    console.log('width:' + width)

    useEffect(() => {
        // 组件挂载完成时的逻辑
        console.log(`Component mounted with props:`);
        const viewportWidth = window.innerWidth;
        // if (viewportWidth >= 1304) {
        //   setFootPc();
        // } else {
        //   setFootMobile();
        // }
        // 清理函数
        return () => {
            console.log(`Component will unmount`);
        };
    }, []);


    return (
        <div className="App">
            <header className="App-header" id='home'>
                <img src={logo} alt='logo' />
                <div className='title-box' ref={titleBox} onClick={(e) => clickTitle(e)}>
                    <a href='#home' style={{
                        display: width > 600 ? 'inherit' : 'none'
                    }} className='head-title first-head-title head-title-active'>首页</a>
                    <a href='#class' style={{
                        display: width > 600 ? 'inherit' : 'none'
                    }} className='head-title'>精品课程</a>
                    <a href='#teacher' className='head-title'>师资团队</a>
                    <a href='#workshow' className='head-title'>学员作品</a>
                    <a href='#media' className='head-title'>媒体报道</a>
                    <a href='#about' className='head-title'>关于我们</a>
                </div>
            </header>
            <div className='bannerBox'>
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner3}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner1}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner2}
                            alt="Second slide"
                        />
                    </Carousel.Item>

                </Carousel>
            </div>
            <div className='single_title_box'>
                <img src={suitableTitle} alt='' />
            </div>
            <div className='row-box'>
                <div className='suitable-left-box'><img src={suitable1} alt='why_study_1' /></div>
                <div className='suitable-right-box'>
                    <div>
                        <img src={suitable2} alt='why_study_2' />
                    </div>
                    <div className='suitable-right-bottom-box'>
                        <img src={suitable3} alt='why_study_3' />
                        <img src={suitable4} alt='why_study_4' />
                    </div>
                </div>
            </div>
            <div style={{
                backgroundImage: `url(${hotClassBg})`,
                backgroundSize: '100% 100%',
                height: '46.5vw',
                position: 'relative'
            }} id='class'>
                <div className='single_title_inbox '>
                    <img src={hotClassTitle} alt='' />
                </div>
                <div className='hot-class'>
                    <div className='hot-class-box'>
                        <div id='hotClass1'>
                            <img src={hotClassContent1} alt='' />
                        </div>
                        <div id='hotClass2'>
                            <img src={hotClassContent2} alt='' />
                        </div>
                        <div id='hotClass3'>
                            <img src={hotClassContent3} alt='' />
                        </div>
                    </div>
                </div>
                <div className='hot-class-btn'>
                    <img src={hotClassBtn} alt='' onClick={() => setShow(true)} />
                </div>
            </div>
            <div id='job' className='single_title_box'>
                <img src={jobTitle} alt='' />
            </div>
            <div className='jobs'>
                <div className='left_box'>
                    <JobBoxComponent
                        title='01'
                        subtitle='ONE'
                        content='品牌插画师'
                        img={jobs1}
                        hover={hoverJobs} />
                    <JobBoxComponent
                        title='02'
                        subtitle='TWO'
                        content='插画导师讲师'
                        img={jobs2}
                        hover={hoverJobs} />
                    <JobBoxComponent
                        title='03'
                        subtitle='THREE'
                        content='游戏原画设计'
                        img={jobs3}
                        hover={hoverJobs} />
                    <JobBoxComponent
                        title='04'
                        subtitle='FOUR'
                        content='游戏美宣插画师'
                        img={jobs4}
                        hover={hoverJobs} />
                    <JobBoxComponent
                        title='05'
                        subtitle='FIVE'
                        content='自由插画师'
                        img={jobs5}
                        hover={hoverJobs} />
                </div>
                <div className='right_box'>
                    <img src={jobImg} alt='' />
                </div>
            </div>
            <div className='job-tips'>
                数据来源：职友集
            </div>
            <div className='job-btn'>
                <img src={jobBtn} alt='' onClick={() => setShow(true)} />
            </div>

            <div id='workshow' className='single_title_box'>
                <img src={workshowTitle} alt='' />
            </div>
            <div className='work_show_box'>
                <img src={workShow} alt='' />
            </div>

            <div className='study_box'>
                <div className='single_title_inbox'>
                    <img src={studyTitle} alt='' />
                </div>
                <div className='studys'>
                    <div>
                        <img src={study1} alt='' />
                        <div className='study_title'>3V1专属服务</div>
                        <div className='study_subtitle'>
                            授课老师、班主任、助教
                            <br />
                            三重辅导与督学
                        </div>
                    </div>
                    <div>
                        <img src={study2} alt='' />
                        <div className='study_title'>作业点评</div>
                        <div className='study_subtitle'>
                            在线提交作业
                            <br />
                            主讲老师实时查看点评
                        </div>
                    </div>
                    <div>
                        <img src={study3} alt='' />
                        <div className='study_title'>接单就业</div>
                        <div className='study_subtitle'>
                            就业老师指导修改
                            <br />
                            让你过稿更轻松
                        </div>
                    </div>
                </div>
            </div>

            <div id='teacher' className='teacher_box'>
                <div className='single_title_inbox'>
                    <img src={teacherTitle} alt='' />
                </div>
                <div className='teacher_carousel'>
                    <Carousel>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={teacher1}
                                alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={teacher2}
                                alt="Second slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={teacher3}
                                alt="Third slide"
                            />
                        </Carousel.Item>
                        {/* 
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={footPc}
              alt="Third slide"
            />
          </Carousel.Item> */}
                    </Carousel>
                </div>
            </div>

            <div id='about' className='about_us'>
                <div className='single_title_inbox'>
                    <img src={aboutusTitle} alt='' />
                </div>
                <div className='about_us_box'>
                    <img className='about_us_person' src={aboutusPerson} alt='' />
                    <div className='about_us_content'>
                        <img src={aboutusSubtitle} alt='' />
                        <div>
                            画星人是一家职业插画师培训机构，专注于提供全方位的插画艺术教育。课程教授插画的基础技能，重视激发和培养学员的创意思维能力。通过行业级讲师授课以及一对一的专业指导，帮助每一位学员成为能够自如驾驭商业插画各个场景的专业插画创作者。
                            <br />
                            无论是初学者还是希望提升至高阶水平的画师，都能在此找到适合自己的成长路径。</div>
                    </div>
                </div>
            </div>
            <div id='media' className='single_title_box'>
                <img src={companyTitle} alt='' />
            </div>
            <div className='company_box'>
                <img src={companys} alt='' />
            </div>
            <footer className='App-footer'>
                <img className='foot-img' src={width > 600 ? foot : footMobile} alt='logo' />
            </footer>
            {
                show && <div className="container-fluid">
                    <div id="wx">
                        <div onClick={() => setShow(false)}><img src={close} className='close' alt='close' /></div>
                    </div>
                </div>
            }
        </div >
    );



    function clickTitle($e) {
        if ($e.target.className.indexOf('head-title') === -1) {
            return;
        }
        let children = Array.from(titleBox.current.children);
        children.forEach(item => {
            const className = item.className.split(' ');
            const i = className.findIndex(i => i === 'head-title-active');
            if (i !== -1) {
                className.splice(i, 1);
                item.className = className.join(' ');
            }
        })
        $e.target.className += ' head-title-active';
    }
}

export default App;
